import styled from "@emotion/styled";
import React from "react";

export type CircleProgressProps = {
  readonly className?: string;
};

const CircleWrap = styled.div`
  width: 100px;
  height: 100px;
  background: #ecf0fa;
  border-radius: 50%;
  border: 1px solid #ecf0fa;

  .circle .mask,
  .circle .fill {
    width: 100px;
    height: 100px;
    position: absolute;
    border-radius: 50%;
  }

  .mask .fill {
    clip: rect(0px, 50px, 100px, 0px);
    background-color: #467ff6;
  }

  .circle .mask {
    clip: rect(0px, 100px, 100px, 50px);
  }

  .mask.full,
  .circle .fill {
    animation: fill ease-in-out 3s;
    transform: rotate(135deg);
  }

  .inside-circle {
    width: 88px;
    height: 88px;
    border-radius: 50%;
    background: #ffffff;
    text-align: center;
    margin-top: 6px;
    margin-left: 6px;
    color: #467ff6;
    position: absolute;
    z-index: 100;
    font-weight: 500;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @keyframes fill {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(135deg);
    }
  }
`;

export function CircleProgress({ className }: CircleProgressProps) {
  return (
    <CircleWrap className={className}>
      <div className="circle">
        <div className="mask half">
          <div className="fill"></div>
        </div>
        <div className="inside-circle">35%</div>
      </div>
    </CircleWrap>
  );
}
