import { clsx } from "clsx";
import { Link } from "gatsby";
import React, { cloneElement } from "react";

import ActiveMinutesImage from "../assets/images/ActiveMinutes.png";
import ActivityGoalImage from "../assets/images/ActivityGoal.png";
import BackImage from "../assets/images/Back.png";
import FireImage from "../assets/images/Fire.png";
import NextImage from "../assets/images/Next.png";
import PlayCircleImage from "../assets/images/PlayCircle.png";
import SampleImage1 from "../assets/images/sample1.jpg";
import { Button, CircleProgress } from "../components";

export default function EducationalDashboardPage() {
  return (
    <div
      className="min-h-screen bg-neutral-100"
      // Main layout wrapper. The nav bar should be inserted here or this should
      // be some wrapping layout component.
    >
      {/* Navigation bar here. */}

      <main className="grid grid-rows-[min-content,1fr] md:grid-cols-[auto,minmax(19rem,calc(25%+2.5rem))] p-6 mx-auto md:max-w-7xl font-graphik">
        <header className="md:flex md:items-end px-5 md:pl-10 md:pr-0 pt-6 pb-3 md:py-10 md:min-h-[10rem] bg-snap-blue rounded-t-[0.875rem] md:rounded-bl-[0.875rem] md:rounded-tr-none">
          <div className="flex flex-col md:flex-row md:items-center gap-3 md:gap-10">
            <h1 className="flex-shrink-0 text-2xl md:text-4xl font-medium text-white">
              Hello, Jenny!
            </h1>
            <p className="text-sm md:text-base leading-[1.375rem] md:leading-[1.375rem] text-snap-light-blue">
              Your training information is updated here.
              <br />
              Dates, new classes, and your progress are all in one tap.
            </p>
          </div>
        </header>

        {/* This is the bottom part of the above header card. It shares a Grid
            area with the classes card to allow for overlapping. */}
        <span
          aria-hidden
          className="row-start-2 md:row-start-1 col-start-1 md:col-start-2 h-11 md:h-full bg-snap-blue rounded-b-[0.875rem] md:rounded-bl-none md:rounded-tr-[0.875rem]"
        />

        {/* This is the "Classes Card". It overlaps with the above span by using
            the same Grid area. */}
        <article className="row-start-2 md:row-start-1 col-start-1 md:col-start-2 md:row-span-2 flex flex-col justify-end items-center px-5 md:px-10 py-6 md:pt-6 md:pb-8 mx-6 md:ml-5 md:mr-10 md:mt-[1.75rem] bg-white rounded-[0.875rem]">
          <CircleProgress className="mb-6" />

          <h1 className="mb-3 text-xl md:text-[1.375rem] leading-[1.375rem] md:leading-6 font-medium text-snap-dark">
            Classes Name
          </h1>
          <h2 className="mb-[0.875rem] md:mb-5 text-base md:text-lg leading-[1.125rem] md:leading-5 font-medium text-snap-blue">
            “Session 2: Name”
          </h2>
          <p className="mb-6 text-sm leading-[1.375rem] text-center text-snap-gray">
            Sed ut perspiciatis unde omnis iste natus error sit volupt
            doloremque.
          </p>
          <Button
            className="py-[1.125rem] md:py-[1.125rem] w-full text-base font-medium leading-[1.125rem] md:leading-[1.125rem]"
            type="button"
          >
            Resume class
          </Button>
        </article>

        {[
          // Activity and goal cards.
          {
            children: (
              <ul className="flex flex-col md:flex-row gap-2 md:gap-3">
                {[
                  {
                    imageAlt: "lightning bolt",
                    imageSrc: ActiveMinutesImage,
                    label: "90 active minutes",
                    labelColorClassName: "text-snap-blue",
                  },
                  {
                    imageAlt: "trophy",
                    imageSrc: ActivityGoalImage,
                    label: "89% of activity goal",
                    labelColorClassName: "text-[#14ca89]",
                  },
                  {
                    imageAlt: "fire",
                    imageSrc: FireImage,
                    label: "8.82 lbs",
                    labelColorClassName: "text-[#fad95b]",
                  },
                ].map(({ imageAlt, imageSrc, label, labelColorClassName }) => (
                  <li
                    className="flex flex-col items-center p-[1.375rem] md:py-[2.625rem] w-full md:w-1/3 bg-white rounded-[0.875rem]"
                    key={imageSrc}
                  >
                    <img
                      alt={imageAlt}
                      className="mb-4 w-7 md:w-8 h-7 md:h-8"
                      src={imageSrc}
                    />
                    <p
                      className={clsx(
                        "text-sm md:text-[1.375rem] leading-4 md:leading-6 font-medium text-center",
                        labelColorClassName,
                      )}
                    >
                      {label}
                    </p>
                  </li>
                ))}
              </ul>
            ),
            header: "This week's progress overview",
          },
          // Class cards.
          {
            children: (
              <ul className="grid auto-rows-[minmax(10rem,auto)] md:grid-cols-[repeat(4,1fr)] gap-3">
                {Array.from({ length: 8 }, (_, index) => ({
                  isAvailable: index < 4,
                  isNew: index > 0 && index < 4,
                  key: index,
                  wrapper:
                    index < 4 ? (
                      <Link
                        // TODO: Replace this with the correct link.
                        to="/curriculum/class1"
                      ></Link>
                    ) : (
                      <div />
                    ),
                })).map(({ isAvailable, isNew, key, wrapper }) => (
                  <li key={key}>
                    {cloneElement(wrapper, {
                      children: (
                        <>
                          <img
                            // TODO: Add proper alt text.
                            alt="class"
                            className="absolute left-0 top-0 w-full h-full rounded-[1.25rem] object-cover pointer-events-none"
                            src={SampleImage1}
                          />
                          <div className="flex-1 w-max z-10">
                            {isNew && (
                              <p className="px-5 py-3 mx-5 my-[0.875rem] text-xs leading-[0.8125rem] font-medium text-snap-blue bg-white rounded-[80px]">
                                New to learn
                              </p>
                            )}
                          </div>
                          <div className="flex items-center z-10">
                            <div className="flex-1 mx-[1.375rem] my-[1.125rem]">
                              <p className="text-[1.125rem] leading-[1.25rem] font-medium text-white">
                                Classes Name
                              </p>
                              {!isAvailable && (
                                <p className="mt-2 text-[0.875rem] leading-[0.9375rem] text-white">
                                  Will be available on 02.02.2022
                                </p>
                              )}
                            </div>
                            {isAvailable && (
                              <img
                                alt="play"
                                className="mr-[1.375rem] my-[1.125rem] w-8 h-8 text-white z-10"
                                src={PlayCircleImage}
                              />
                            )}
                          </div>
                        </>
                      ),
                      className: clsx(
                        "flex flex-col justify-between relative h-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-snap-blue",
                        isAvailable && "hover:opacity-80 transition-opacity",
                      ),
                    })}
                  </li>
                ))}
              </ul>
            ),
            header: "The following classes for you",
            headerRightElement: (
              <div className="hidden md:block position absolute right-0 top-[-1rem]">
                {(["Back", "Next"] as const).map((label) => (
                  // TODO: Fix images.
                  <button key={label} type="button">
                    <img
                      alt={label}
                      className="w-[60px] h-[60px]"
                      src={label === "Back" ? BackImage : NextImage}
                    />
                  </button>
                ))}
              </div>
            ),
          },
        ].map(({ children, header, headerRightElement }, index) => (
          <article
            className={clsx(
              "mt-10",
              index === 0 ? "md:mt-10" : "md:mt-[3.75rem]",
              index > 0 && "md:col-span-2",
            )}
            key={header}
          >
            <header className="flex relative mb-5 md:mb-7">
              <h1 className="text-lg md:text-xl leading-5 md:leading-[1.375rem] font-medium text-snap-dark">
                {header}
              </h1>
              {headerRightElement}
            </header>
            <main>{children}</main>
          </article>
        ))}
      </main>

      {/* Footer here. */}
    </div>
  );
}
